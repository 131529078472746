// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-design-approach-js": () => import("./../../../src/pages/about-us/design-approach.js" /* webpackChunkName: "component---src-pages-about-us-design-approach-js" */),
  "component---src-pages-about-us-profile-js": () => import("./../../../src/pages/about-us/profile.js" /* webpackChunkName: "component---src-pages-about-us-profile-js" */),
  "component---src-pages-about-us-the-team-js": () => import("./../../../src/pages/about-us/the-team.js" /* webpackChunkName: "component---src-pages-about-us-the-team-js" */),
  "component---src-pages-about-us-value-and-commitment-js": () => import("./../../../src/pages/about-us/value-and-commitment.js" /* webpackChunkName: "component---src-pages-about-us-value-and-commitment-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-our-work-1-index-js": () => import("./../../../src/pages/our-work/1index.js" /* webpackChunkName: "component---src-pages-our-work-1-index-js" */),
  "component---src-pages-our-work-commercial-1-index-js": () => import("./../../../src/pages/our-work/commercial/1index.js" /* webpackChunkName: "component---src-pages-our-work-commercial-1-index-js" */),
  "component---src-pages-our-work-commercial-boc-offices-belfast-js": () => import("./../../../src/pages/our-work/commercial/boc-offices-belfast.js" /* webpackChunkName: "component---src-pages-our-work-commercial-boc-offices-belfast-js" */),
  "component---src-pages-our-work-commercial-vivomed-offices-downpatrick-js": () => import("./../../../src/pages/our-work/commercial/vivomed-offices-downpatrick.js" /* webpackChunkName: "component---src-pages-our-work-commercial-vivomed-offices-downpatrick-js" */),
  "component---src-pages-our-work-community-1-index-js": () => import("./../../../src/pages/our-work/community/1index.js" /* webpackChunkName: "component---src-pages-our-work-community-1-index-js" */),
  "component---src-pages-our-work-community-raffrey-church-halls-crossgar-js": () => import("./../../../src/pages/our-work/community/raffrey-church-halls-crossgar.js" /* webpackChunkName: "component---src-pages-our-work-community-raffrey-church-halls-crossgar-js" */),
  "component---src-pages-our-work-industrial-ashvale-engineering-downpatrick-js": () => import("./../../../src/pages/our-work/industrial/ashvale-engineering-downpatrick.js" /* webpackChunkName: "component---src-pages-our-work-industrial-ashvale-engineering-downpatrick-js" */),
  "component---src-pages-our-work-industrial-index-js": () => import("./../../../src/pages/our-work/industrial/index.js" /* webpackChunkName: "component---src-pages-our-work-industrial-index-js" */),
  "component---src-pages-our-work-leisure-andy-ward-leisure-ballynahinch-js": () => import("./../../../src/pages/our-work/leisure/andy-ward-leisure-ballynahinch.js" /* webpackChunkName: "component---src-pages-our-work-leisure-andy-ward-leisure-ballynahinch-js" */),
  "component---src-pages-our-work-leisure-index-js": () => import("./../../../src/pages/our-work/leisure/index.js" /* webpackChunkName: "component---src-pages-our-work-leisure-index-js" */),
  "component---src-pages-our-work-residential-1-index-js": () => import("./../../../src/pages/our-work/residential/1index.js" /* webpackChunkName: "component---src-pages-our-work-residential-1-index-js" */),
  "component---src-pages-our-work-residential-apartments-dunallen-apartments-donaghadee-js": () => import("./../../../src/pages/our-work/residential/apartments/dunallen-apartments-donaghadee.js" /* webpackChunkName: "component---src-pages-our-work-residential-apartments-dunallen-apartments-donaghadee-js" */),
  "component---src-pages-our-work-residential-apartments-hillsborough-old-road-apartments-js": () => import("./../../../src/pages/our-work/residential/apartments/hillsborough-old-road-apartments.js" /* webpackChunkName: "component---src-pages-our-work-residential-apartments-hillsborough-old-road-apartments-js" */),
  "component---src-pages-our-work-residential-apartments-index-js": () => import("./../../../src/pages/our-work/residential/apartments/index.js" /* webpackChunkName: "component---src-pages-our-work-residential-apartments-index-js" */),
  "component---src-pages-our-work-residential-developments-index-js": () => import("./../../../src/pages/our-work/residential/developments/index.js" /* webpackChunkName: "component---src-pages-our-work-residential-developments-index-js" */),
  "component---src-pages-our-work-residential-developments-mews-housing-development-ballyhalbert-js": () => import("./../../../src/pages/our-work/residential/developments/mews-housing-development-ballyhalbert.js" /* webpackChunkName: "component---src-pages-our-work-residential-developments-mews-housing-development-ballyhalbert-js" */),
  "component---src-pages-our-work-residential-developments-morey-peaks-housing-development-donaghadee-js": () => import("./../../../src/pages/our-work/residential/developments/morey-peaks-housing-development-donaghadee.js" /* webpackChunkName: "component---src-pages-our-work-residential-developments-morey-peaks-housing-development-donaghadee-js" */),
  "component---src-pages-our-work-residential-extensions-extension-five-js": () => import("./../../../src/pages/our-work/residential/extensions/extension-five.js" /* webpackChunkName: "component---src-pages-our-work-residential-extensions-extension-five-js" */),
  "component---src-pages-our-work-residential-extensions-extension-four-js": () => import("./../../../src/pages/our-work/residential/extensions/extension-four.js" /* webpackChunkName: "component---src-pages-our-work-residential-extensions-extension-four-js" */),
  "component---src-pages-our-work-residential-extensions-extension-one-js": () => import("./../../../src/pages/our-work/residential/extensions/extension-one.js" /* webpackChunkName: "component---src-pages-our-work-residential-extensions-extension-one-js" */),
  "component---src-pages-our-work-residential-extensions-extension-seven-js": () => import("./../../../src/pages/our-work/residential/extensions/extension-seven.js" /* webpackChunkName: "component---src-pages-our-work-residential-extensions-extension-seven-js" */),
  "component---src-pages-our-work-residential-extensions-extension-six-js": () => import("./../../../src/pages/our-work/residential/extensions/extension-six.js" /* webpackChunkName: "component---src-pages-our-work-residential-extensions-extension-six-js" */),
  "component---src-pages-our-work-residential-extensions-extension-three-js": () => import("./../../../src/pages/our-work/residential/extensions/extension-three.js" /* webpackChunkName: "component---src-pages-our-work-residential-extensions-extension-three-js" */),
  "component---src-pages-our-work-residential-extensions-extension-two-js": () => import("./../../../src/pages/our-work/residential/extensions/extension-two.js" /* webpackChunkName: "component---src-pages-our-work-residential-extensions-extension-two-js" */),
  "component---src-pages-our-work-residential-extensions-index-js": () => import("./../../../src/pages/our-work/residential/extensions/index.js" /* webpackChunkName: "component---src-pages-our-work-residential-extensions-index-js" */),
  "component---src-pages-our-work-residential-houses-1-index-js": () => import("./../../../src/pages/our-work/residential/houses/1index.js" /* webpackChunkName: "component---src-pages-our-work-residential-houses-1-index-js" */),
  "component---src-pages-our-work-residential-houses-house-eight-js": () => import("./../../../src/pages/our-work/residential/houses/house-eight.js" /* webpackChunkName: "component---src-pages-our-work-residential-houses-house-eight-js" */),
  "component---src-pages-our-work-residential-houses-house-five-js": () => import("./../../../src/pages/our-work/residential/houses/house-five.js" /* webpackChunkName: "component---src-pages-our-work-residential-houses-house-five-js" */),
  "component---src-pages-our-work-residential-houses-house-four-js": () => import("./../../../src/pages/our-work/residential/houses/house-four.js" /* webpackChunkName: "component---src-pages-our-work-residential-houses-house-four-js" */),
  "component---src-pages-our-work-residential-houses-house-one-js": () => import("./../../../src/pages/our-work/residential/houses/house-one.js" /* webpackChunkName: "component---src-pages-our-work-residential-houses-house-one-js" */),
  "component---src-pages-our-work-residential-houses-house-seven-js": () => import("./../../../src/pages/our-work/residential/houses/house-seven.js" /* webpackChunkName: "component---src-pages-our-work-residential-houses-house-seven-js" */),
  "component---src-pages-our-work-residential-houses-house-six-js": () => import("./../../../src/pages/our-work/residential/houses/house-six.js" /* webpackChunkName: "component---src-pages-our-work-residential-houses-house-six-js" */),
  "component---src-pages-our-work-residential-houses-house-three-js": () => import("./../../../src/pages/our-work/residential/houses/house-three.js" /* webpackChunkName: "component---src-pages-our-work-residential-houses-house-three-js" */),
  "component---src-pages-our-work-residential-houses-house-two-js": () => import("./../../../src/pages/our-work/residential/houses/house-two.js" /* webpackChunkName: "component---src-pages-our-work-residential-houses-house-two-js" */),
  "component---src-pages-our-work-retail-index-js": () => import("./../../../src/pages/our-work/retail/index.js" /* webpackChunkName: "component---src-pages-our-work-retail-index-js" */),
  "component---src-pages-our-work-retail-life-style-sports-bow-street-mall-lisburn-js": () => import("./../../../src/pages/our-work/retail/life-style-sports-bow-street-mall-lisburn.js" /* webpackChunkName: "component---src-pages-our-work-retail-life-style-sports-bow-street-mall-lisburn-js" */),
  "component---src-pages-our-work-retail-life-style-sports-castle-lane-belfast-js": () => import("./../../../src/pages/our-work/retail/life-style-sports-castle-lane-belfast.js" /* webpackChunkName: "component---src-pages-our-work-retail-life-style-sports-castle-lane-belfast-js" */),
  "component---src-pages-what-we-do-building-control-js": () => import("./../../../src/pages/what-we-do/building-control.js" /* webpackChunkName: "component---src-pages-what-we-do-building-control-js" */),
  "component---src-pages-what-we-do-index-js": () => import("./../../../src/pages/what-we-do/index.js" /* webpackChunkName: "component---src-pages-what-we-do-index-js" */),
  "component---src-pages-what-we-do-initial-designs-js": () => import("./../../../src/pages/what-we-do/initial-designs.js" /* webpackChunkName: "component---src-pages-what-we-do-initial-designs-js" */),
  "component---src-pages-what-we-do-other-services-js": () => import("./../../../src/pages/what-we-do/other-services.js" /* webpackChunkName: "component---src-pages-what-we-do-other-services-js" */),
  "component---src-pages-what-we-do-planning-approvals-js": () => import("./../../../src/pages/what-we-do/planning-approvals.js" /* webpackChunkName: "component---src-pages-what-we-do-planning-approvals-js" */),
  "component---src-pages-what-we-do-site-operations-js": () => import("./../../../src/pages/what-we-do/site-operations.js" /* webpackChunkName: "component---src-pages-what-we-do-site-operations-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

